import { log } from '@hooks/logger';
import { ErrorCode } from '@typings/ErrorCode';
import { LogLevel, Maybe } from '@typings/graphql';
import { DIS } from '@typings/dis';

export interface AppRolekeys {
  appName: string | null;
  roleKeys: string[];
}
export interface AppKeyRoledata {
  [key: string]: AppRolekeys;
}
export interface AppData {
  appData: AppKeyRoledata;
  roles: Maybe<DIS.RolesAuthenticationTicketData>[];
}

export default async function groupApplicationRolesRelationshipsMapper(
  authControllerResponse: DIS.SSOTicketResponse
): Promise<AppData> {
  const apps: AppKeyRoledata = {};
  const {
    groupApplicationRolesRelationship,
    rolesAuthenticationTicketData,
  } = authControllerResponse;
  if (!groupApplicationRolesRelationship || !rolesAuthenticationTicketData) {
    log({
      level: LogLevel.Error,
      message: ErrorCode.AuthcontrollerDataNotFound,
      other: {
        backendError:
          'Authcontroller does not contain user data, cannot proceed further.',
      },
    });
  }
  groupApplicationRolesRelationship.forEach(relationship => {
    // Skip relationships with no permissions.
    if (relationship && !relationship.applications) {
      return;
    }
    if (relationship?.applications) {
      relationship.applications.forEach(application => {
        const appId = application?.appInstanceId;
        if (appId && !apps[appId]) {
          apps[appId] = {
            appName: application.name || null,
            roleKeys: [],
          };
        }
        if (relationship.roles) {
          relationship.roles.forEach(appRole => {
            const roleKeyRef = appRole?.roleKey;
            if (
              appId &&
              roleKeyRef &&
              !apps[appId].roleKeys.find(item => item === roleKeyRef)
            ) {
              apps[appId].roleKeys.push(roleKeyRef);
            }
          });
        }
      });
    }
  });
  const mappedAuthData: AppData = {
    appData: apps,
    roles: rolesAuthenticationTicketData,
  };
  log({
    level: LogLevel.Info,
    message: 'GroupApplicationRolesRelationshipsMapper Data:',
    other: {
      AppData: JSON.stringify(mappedAuthData, null, 2),
    },
  });
  return mappedAuthData;
}
